import {AnimatePresence, domAnimation, LazyMotion} from 'framer-motion'
import * as React from 'react'

export const TRANSITION_EXIT = 0.2
export const TRANSITION_ENTER = 0.2
export const TRANSITION_DELAY = 0

export const PageTransitionWrapper = ({children, initial = true}) => (
  <LazyMotion features={domAnimation} strict>
    <AnimatePresence mode='wait' initial={initial}>
      {children}
    </AnimatePresence>
  </LazyMotion>
)

export const PageTransition = (pageProps) => {
  const isFirstRenderRef = React.useRef(true)

  React.useEffect(() => {
    isFirstRenderRef.current = false
  }, [])

  return (
    <PageTransitionWrapper>
      <div key={pageProps.path}></div>
    </PageTransitionWrapper>
  )
}
