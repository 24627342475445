import {GTM_ID, IS_DEVELOPMENT} from '@/common/config'

export function loadScripts() {
  const searchParams = new URLSearchParams(window.location.search)
  const isStoryblok = !!searchParams.get('_storyblok')

  if (!IS_DEVELOPMENT && !isStoryblok) {
    function loadGTM() {
      if (GTM_ID) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'})
        const firstScriptTag = document.getElementsByTagName('script')[0]
        const googleScript = document.createElement('script')
        googleScript.async = true
        googleScript.src = `/aT8Qm5ejKk?id=${GTM_ID}`
        firstScriptTag.parentNode.insertBefore(googleScript, firstScriptTag)
      }
    }

    loadGTM()
  }
}
