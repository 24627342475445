/* eslint-disable no-warning-comments */

import './src/styles/globals.scss'

import * as React from 'react'

import {PageTransition, PageTransitionWrapper, TRANSITION_EXIT} from '@/components/PageTransition'
import {ScrollSmootherContext, ScrollSmootherWrapper} from '@/components/ScrollSmoother'

export const wrapRootElement = ({element}) => {
  return <ScrollSmootherContext>{element}</ScrollSmootherContext>
}

export const wrapPageElement = ({element, props}) => {
  return (
    <>
      <PageTransition {...props} />
      <ScrollSmootherWrapper>
        <PageTransitionWrapper initial={false}>{element}</PageTransitionWrapper>
      </ScrollSmootherWrapper>
    </>
  )
}

export const onPreRouteUpdate = (props) => {
  // Prevent ScrollSmoother scrolling to top
  if (window.__smoother) {
    const shouldPause =
      !props || props.location.pathname === props.prevLocation?.pathname ? false : true
    window.__smoother.paused(shouldPause)
  }
}

export const shouldUpdateScroll = ({
  routerProps: {location},
  prevRouterProps,
  getSavedScrollPosition,
}) => {
  const duration = TRANSITION_EXIT * 1000

  if (window.__smoother) {
    // Anchor
    if (
      prevRouterProps &&
      location.pathname === prevRouterProps.location.pathname &&
      location.hash
    ) {
      window.__smoother.paused(false).scrollTo(location.hash, true, 'top top')
    }
    // Same URL
    else if (prevRouterProps && location.pathname === prevRouterProps.location.pathname) {
      window.__smoother.paused(false)
      window.__smoother.scrollTo(0, true)
    }
    // Link
    else if (location.action === 'PUSH') {
      window.setTimeout(() => window.__smoother.scrollTop(0), duration)
    }
    // Browser's forwards or back button
    else {
      const savedPosition = getSavedScrollPosition(location) || [0, 0]
      window.__smoother.paused(false)
      window.setTimeout(
        () => window.__smoother.scrollTo(savedPosition[1], true, 'top top'),
        duration * 2
      )
    }
  }

  return false
}
